import {
    MarketDataServiceClient,
    MarketDataServiceDefinition
} from '@/compiled_proto/com/celertech/marketdata/api/notification/MarketDataServiceProto';
import { addMultipleMarkets } from '@/services/MarketService';
import { Logger } from '@/utils/logger';
import { callbackOnlyInMainPage } from '@/utils/middleware';
import { createChannel, createClient } from 'nice-grpc-web';
import { Middleware } from 'redux';
import { controlClearSubscriptions } from '../reducers/authSlice';
import { MarketItem } from '../reducers/celerMarketSlice';
import { appendSubscriptions, clearSubscriptions, generatePair } from '../reducers/marketPairSlice';
import { AppDispatch, RootState } from '../store';

const wsChannelUrl = window.config.integration.celertech.websocket;
const wsChannel = createChannel(wsChannelUrl);
const marketDataServiceClient: MarketDataServiceClient = createClient(MarketDataServiceDefinition, wsChannel);

const ChartDataMiddleware: Middleware = (store) => (next) => async (action) => {
    const dispatch: AppDispatch = store.dispatch;
    const state: RootState = store.getState();
    const credentials = state.auth.user;

    if (controlClearSubscriptions.match(action)) {
        Logger({ title: `ChartDataMiddleware: Clear Subscriptions`, callback: () => {} });
        dispatch(clearSubscriptions());
    } else if ('marketPair/fetch/fulfilled'.match(action.type) && credentials) {
        callbackOnlyInMainPage(async () => {
            const state: RootState = store.getState();
            const subscriptions = state.marketPair.subscriptions || {};

            const items: MarketItem[] = action.payload;
            const marketPairs = items.map((item) => generatePair(item.securityCode));

            const subscribedPairs = Object.values(subscriptions).map((subInfo: any) => subInfo.pair);
            const pairsToSubscribe = marketPairs.filter(
                (marketPair) => subscribedPairs.indexOf(marketPair.celer) === -1
            );
            const celerPairsToSubscribe = pairsToSubscribe.map((pair) => pair.celer);

            Logger({
                title: 'Outbound: Subscribing to Celer Prices',
                callback: () => {
                    console.log({ celerPairsToSubscribe });
                }
            });

            // await addSubscriptions(credentials, celerPairsToSubscribe, dispatch);
            await throttledSubscriptions(credentials, celerPairsToSubscribe, dispatch);
        });
    }

    // Pass on to next middlewares in line
    next(action);
};

export default ChartDataMiddleware;

const throttledSubscriptions = async (credentials, celerPairsToSubscribe, dispatch) => {
    const chunkSize = 10;
    for (let i = 0; i < celerPairsToSubscribe.length; i += chunkSize) {
        const chunk = celerPairsToSubscribe.slice(i, i + chunkSize);
        await addSubscriptions(credentials, chunk, dispatch);
    }
};

const addSubscriptions = async (credentials, chunk, dispatch) => {
    try {
        const addedSubscriptions = {};
        const addedMarketItemList = await addMultipleMarkets(marketDataServiceClient, credentials, chunk);
        if (addedMarketItemList) {
            addedMarketItemList.forEach((addedMarketItem) => {
                addedSubscriptions[addedMarketItem.subscriptionId] = {
                    type: 'tob',
                    pair: addedMarketItem.securityCode,
                    exchange: addedMarketItem.exchangeCode,
                    settlementType: addedMarketItem.settlementType
                };
            });
        }
        dispatch(appendSubscriptions(addedSubscriptions));
    } catch (error) {
        dispatch(clearSubscriptions());
    }
};
