import { Button } from '@/components/common/Button';
import Divider from '@/components/common/Divider';
import Drawer from '@/components/common/Drawer';
import Entry from '@/components/common/Entry';
import {
    InternalTransferRequestFormInput,
    InternalTransferRequestFormValues,
    internalTransferRequestSchema
} from '@/components/form/schema/internalTransferRequestSchema';
import ErrorMessage from '@/components/inputs/ErrorMessage';
import FilterSelect from '@/components/inputs/FilterSelect';
import InputController from '@/components/inputs/InputController';
import NumberInput from '@/components/inputs/NumberInput';
import Select from '@/components/inputs/Select';
import TextArea from '@/components/inputs/TextArea';
import { convertedQtyFormatterConfig } from '@/config/config';
import { portalAPI } from '@/helpers/environmentHelper';
import useToast from '@/utils/hooks/useToast';
import { useSso } from '@/utils/providers/SSOProvider';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { useNumberFormatter } from 'react-aria';
import { FieldErrors, FormProvider, useForm } from 'react-hook-form';
import Modal, { ModalClose, ModalHeader, ModalProps, ModalTitle } from '../Modal';
import { WhitelistError } from './WithdrawalRequestModal';

type Option = {
    label: string;
    value: string;
};

export enum WithdrawalType {
    FIAT = 'FIAT',
    CRYPTO = 'CRYPTO'
}

export const transferTypeOptions: Option[] = [
    {
        label: WithdrawalType.FIAT,
        value: WithdrawalType.FIAT
    },
    {
        label: WithdrawalType.CRYPTO,
        value: WithdrawalType.CRYPTO
    }
];

const defaultCounterPartId = { label: 'Please select a counterpart', value: undefined };
const defaultCurrencyId = { label: 'Please select a currency', value: undefined };
const defaultAssetId = { label: 'Please select a asset', value: undefined };
const defaultInternalTransferType = transferTypeOptions[0];

const defaultValues: Partial<InternalTransferRequestFormValues> = {
    amount: undefined,
    transferType: defaultInternalTransferType,
    counterPartId: defaultCounterPartId,
    currencyId: defaultCurrencyId,
    requestDescription: ''
};

interface InternalTransferRequestModalProps extends ModalProps {}

const InternalTransferRequestModal = (props: InternalTransferRequestModalProps) => {
    const { opened, handlers } = props;

    const { jwt, foundEntity, balancesApi, currenciesApi, linkedEntitiesApi, bankInfoApi, walletInfoApi } = useSso();
    const convertedQtyFormatter = useNumberFormatter(convertedQtyFormatterConfig);

    const [error, setError] = useState<any>({ message: '' });
    const [showBalanceAmount, setShowBalanceAmount] = useState(false);
    const [availableBalance, setAvailableBalance] = useState(0);

    const [toastError, toastSuccess] = useToast();

    const formInstance = useForm<InternalTransferRequestFormInput>({
        defaultValues,
        mode: 'onChange',
        resolver: yupResolver(internalTransferRequestSchema(availableBalance, foundEntity))
    });

    const {
        reset,
        watch,
        setValue,
        handleSubmit,
        formState: { isSubmitting, isValid }
    } = formInstance;

    const [selectedTransferType, selectedCounterPartId, selectedCurrency] = watch([
        'transferType',
        'counterPartId',
        'currencyId'
    ]);

    const { data: currencies, isLoading: isCurrenciesLoading } = currenciesApi;
    const { data: balances, isLoading: isBalancesLoading } = balancesApi;
    const { data: bankInfo, isLoading: isBankInfoLoading } = bankInfoApi;
    const { data: wallets, isLoading: isWalletLoading } = walletInfoApi;
    const { data: linkedEntities, isLoading: isLinkedEntitiesLoading } = linkedEntitiesApi;

    const currencyOptions = useMemo(() => {
        return currencies
            ? currencies.data
                  .filter(
                      (currency: any) => currency.enabledForWithdrawal && currency.type === selectedTransferType?.value
                  )
                  .map((currency: any) => {
                      return {
                          label: currency.digitalAssetName || currency.name,
                          value: currency.id
                      };
                  })
            : [];
    }, [currencies, selectedTransferType]);

    const bankInfoOptions = useMemo(() => {
        return bankInfo
            ? bankInfo.data
                  ?.filter((bankInfo) => bankInfo.active && bankInfo.baseCurrencyId === selectedCurrency?.value)
                  ?.map((bankInfo) => ({
                      label: bankInfo.friendlyName,
                      value: bankInfo.bankInfoId
                  }))
            : [];
    }, [bankInfo, selectedCurrency]);

    const walletOptions = useMemo(() => {
        return wallets
            ? wallets.data
                  ?.filter((wallet) =>
                      wallet.supportedAssets.find((asset) => asset.currencyId === selectedCurrency?.value)
                  )
                  ?.map((wallet) => ({
                      label: wallet.friendlyName,
                      value: wallet.walletId
                  }))
            : [];
    }, [wallets, selectedCurrency]);

    const hasAccount = useMemo(() => {
        return selectedCurrency?.value && (bankInfoOptions?.length || walletOptions?.length);
    }, [selectedCurrency, bankInfoOptions, walletOptions]);

    const counterPartOptions = useMemo(() => {
        return linkedEntities
            ? linkedEntities.data
                  .filter((entity) => {
                      return entity.entityId !== foundEntity?.entityId;
                  })
                  .map((entity) => ({
                      label: entity.entityName,
                      value: entity.entityId
                  }))
            : [];
    }, [linkedEntities, foundEntity]);

    useEffect(() => {
        if (counterPartOptions && counterPartOptions.length === 1) {
            setValue('counterPartId', counterPartOptions[0]);
        } else {
            setValue('counterPartId', defaultCounterPartId);
        }
    }, [counterPartOptions]);

    const currentBalance = useMemo(() => {
        return balances?.data?.find((balance: any) => balance.currencyName === selectedCurrency?.label)?.balance || 0;
    }, [balances, selectedCurrency]);

    const onSubmit = async (data: InternalTransferRequestFormInput) => {
        const dataValidated = data as InternalTransferRequestFormValues;

        try {
            const payload = {
                entityId: foundEntity.entityId,
                amount: dataValidated.amount || 0,
                requestDescription: dataValidated.requestDescription || null,
                currencyId: dataValidated.currencyId.value,
                counterpartEntityId: dataValidated.counterPartId.value
            };
            await axios.post(portalAPI('/api/requests/internal-transfer'), payload, {
                headers: { Authorization: `Bearer ${jwt}` }
            });
            const title = 'Internal Transfer';
            const body = 'Internal Transfer request submitted successfully';
            toastSuccess({ body, title });
            handlers.close();
        } catch (err: any) {
            setError(err?.response?.data || err);
        }
    };

    const onError = (e: FieldErrors) => console.log(e);

    useEffect(() => {
        if (opened && selectedTransferType?.value)
            reset({
                ...defaultValues,
                transferType: selectedTransferType,
                counterPartId: counterPartOptions?.length === 1 ? counterPartOptions[0] : selectedCounterPartId,
                currencyId: selectedTransferType?.value === WithdrawalType.FIAT ? defaultCurrencyId : defaultAssetId,
                amount: 0
            });
    }, [selectedTransferType?.value]);

    useEffect(() => {
        if (opened && selectedCurrency?.value) {
            reset({
                ...defaultValues,
                transferType: selectedTransferType,
                currencyId: selectedCurrency,
                counterPartId: counterPartOptions?.length === 1 ? counterPartOptions[0] : selectedCounterPartId,
                amount: 0
            });
        }
    }, [selectedCurrency?.value]);

    useEffect(() => {
        if (opened && selectedCurrency?.value) {
            if (!hasAccount) {
                setShowBalanceAmount(false);
                if (selectedTransferType?.value)
                    setError({
                        message: (
                            <WhitelistError
                                type={selectedTransferType?.value as any}
                                selectedCurrency={selectedCurrency}
                            />
                        )
                    });
            } else {
                if (!foundEntity?.permissions?.includes('ACCOUNT_VIEW')) setShowBalanceAmount(false);
                else setShowBalanceAmount(true);
                setError({ message: '' });
            }
        }
    }, [bankInfoOptions, walletOptions, selectedCurrency, foundEntity, setValue]);

    useEffect(() => {
        setAvailableBalance(currentBalance);
    }, [currentBalance]);

    useEffect(() => {
        if (!opened) {
            reset(defaultValues, { keepIsValid: false });
            setError({ message: '' });
            setShowBalanceAmount(false);
        }
    }, [opened]);

    return (
        <Modal {...props} className="h-auto" size="max-w-2xl">
            <div className="h-full flex flex-col lg:block lg:h-auto">
                <ModalHeader>
                    <ModalTitle>Internal Transfer</ModalTitle>
                    <ModalClose handlers={handlers} />
                </ModalHeader>
                <Divider />
                <FormProvider {...formInstance}>
                    <form
                        autoComplete="off"
                        className="h-full flex flex-col"
                        onSubmit={handleSubmit(onSubmit, onError)}>
                        <Divider />
                        <Drawer.Body>
                            <Drawer.SubBody>
                                <InputController
                                    name="transferType"
                                    label="Transfer Type"
                                    placeholder="Withdrawal Type"
                                    required>
                                    <Select options={transferTypeOptions} />
                                </InputController>
                                <InputController
                                    name="currencyId"
                                    label={selectedTransferType?.value === 'FIAT' ? 'Currency' : 'Asset'}
                                    placeholder="Currency"
                                    required>
                                    <FilterSelect options={currencyOptions} enableSorting />
                                </InputController>
                                <InputController
                                    name="amount"
                                    label="Amount"
                                    placeholder="Amount"
                                    inputInfo={
                                        showBalanceAmount ? (
                                            <i className="text-sm text-yellow-400">
                                                Current {selectedTransferType?.value === 'FIAT' ? 'Account' : 'Wallet'}{' '}
                                                Balance {selectedCurrency?.value && selectedCurrency?.label}{' '}
                                                {convertedQtyFormatter.format(currentBalance)}
                                            </i>
                                        ) : undefined
                                    }
                                    required
                                    disabled={!selectedCurrency?.value || !hasAccount}>
                                    <NumberInput />
                                </InputController>
                                <Entry disabled label="Current Account" value={foundEntity?.entityName}></Entry>
                                <InputController
                                    name="requestDescription"
                                    label="Request Description"
                                    placeholder="Request Description"
                                    alignStart>
                                    <TextArea rows={2} />
                                </InputController>
                            </Drawer.SubBody>

                            <Divider />
                            <Drawer.SubBody>
                                <InputController
                                    name="counterPartId"
                                    label="Counterpart"
                                    placeholder="Counterpart"
                                    asyncInput={isLinkedEntitiesLoading}
                                    disabled={counterPartOptions?.length < 2}
                                    required>
                                    <Select options={counterPartOptions} enableSorting />
                                </InputController>

                                <ErrorMessage error={error} />
                            </Drawer.SubBody>
                        </Drawer.Body>
                        <Divider />
                        <Drawer.Footer>
                            <button
                                type="button"
                                className="rounded-md p-2 px-4 bg-neutral-600 hover:bg-neutral-500"
                                onClick={() => handlers.close()}>
                                Cancel
                            </button>
                            <Button className="!w-auto" isLoading={isSubmitting} disabled={!isValid}>
                                Submit Request
                            </Button>
                        </Drawer.Footer>
                    </form>
                </FormProvider>
            </div>
        </Modal>
    );
};

export default InternalTransferRequestModal;
