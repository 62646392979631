import {
    MarketDataPriceEvent,
    MarketDataServiceClient,
    MarketDataServiceDefinition
} from '@/compiled_proto/com/celertech/marketdata/api/notification/MarketDataServiceProto';
import { useAppDispatch, useAppSelector } from '@/state/hooks';
import { User, selectCredentials } from '@/state/reducers/authSlice';
import { setBalanceStatus } from '@/state/reducers/balanceSlice';
import { clearOrderBook, updateOrderBook } from '@/state/reducers/celerMarketSlice';
import { pushPriceChange, resetChart } from '@/state/reducers/chartDataSlice';
import { addBidAsk, clearBidAsk, generatePair, selectActiveMarketPair } from '@/state/reducers/marketPairSlice';
import { Metadata, createChannel, createClient } from 'nice-grpc-web';
import { createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { WebSocketHook } from 'react-use-websocket/dist/lib/types';
import { callbackOnlyInMainPage } from '../middleware';
import { convertPriceEvent } from '../pricebook';

export interface MarketDataContext extends Partial<WebSocketHook> {}

export interface MarketDataProviderProps {
    children: React.ReactNode;
}

const celerTechURL = window.config.integration.celertech.authority;
const livePricesType = window.config.modules.chart?.livePricesType || 'bid';
const wsChannelUrl = window.config.integration.celertech.websocket;
const wsChannel = createChannel(wsChannelUrl);
const marketDataServiceClient: MarketDataServiceClient = createClient(MarketDataServiceDefinition, wsChannel);

const MarketDataContext = createContext({} as MarketDataContext);
export const useMarketData = () => useContext(MarketDataContext);
export const MarketDataProvider = (props: MarketDataProviderProps) => {
    const dispatch = useAppDispatch();
    const credentials = useAppSelector(selectCredentials);
    const activePair = useAppSelector(selectActiveMarketPair);

    const wsURL = useMemo(() => {
        if (credentials) return `wss://${celerTechURL}/marketdataprices`;
        else return null;
    }, [credentials]);

    const websocket = useWebSocket(wsURL, {
        share: false,
        shouldReconnect: () => true
    });

    const { readyState, lastJsonMessage, getWebSocket, sendMessage } = websocket;

    const priceChangeFilter = useCallback((celerCode: string) => celerCode === activePair.celer, [activePair]);

    const callback = useCallback(
        async (marketSub: MarketDataPriceEvent) => {
            if (!marketSub.isHeartBeat && marketSub.fullSnapshot?.securityCode) {
                const market = generatePair(marketSub.fullSnapshot.securityCode);
                const converted = convertPriceEvent(marketSub);
                if (converted) {
                    const bestBid = converted.priceBook.find((pb) => pb.type === 'bids')?.price;
                    const bestAsk = converted.priceBook.find((pb) => pb.type === 'asks')?.price;
                    const bestMid = converted.priceBook.find((pb) => pb.type === 'midprice')?.price;

                    if (bestBid && bestAsk && bestMid) {
                        if (priceChangeFilter(market.celer)) {
                            dispatch(
                                pushPriceChange({
                                    symbol: market.netdania,
                                    price: livePricesType === 'mid' ? bestMid : bestBid,
                                    timestamp: parseInt(converted.snapshotMillis)
                                })
                            );
                        }
                        dispatch(addBidAsk({ celerPair: market.celer, bid: bestBid, ask: bestAsk }));
                    }
                    dispatch(updateOrderBook({ priceEvent: converted }));
                } else {
                    dispatch(clearBidAsk({ celerPair: market.celer }));
                    dispatch(clearOrderBook(marketSub.fullSnapshot.securityCode));
                }
            }
        },
        [priceChangeFilter]
    );

    useEffect(() => {
        dispatch(setBalanceStatus(ReadyState[readyState] as keyof typeof ReadyState));
        if (readyState === ReadyState.OPEN && credentials) {
            sendMessage(credentials.authToken);
        }
    }, [readyState]);

    useEffect(() => {
        if (lastJsonMessage?.fullSnapshot) {
            const event = lastJsonMessage as MarketDataPriceEvent;
            callback(event);
        }
    }, [lastJsonMessage]);

    useEffect(() => {
        // When switching between pairs, we need to degrade the current pair and upgrade the new pair
        if (credentials && activePair.celer) {
            callbackOnlyInMainPage(() => {
                // Dispath action to fetch data
                dispatch(resetChart());
            });
        }
    }, [credentials, activePair]);

    useEffect(() => {
        if (credentials) setupMarketSubscriptionWS(credentials);
    }, [credentials]);

    const value = useMemo<MarketDataContext>(() => {
        return { readyState, lastJsonMessage, getWebSocket };
    }, [readyState, lastJsonMessage, getWebSocket]);

    return <MarketDataContext.Provider value={value}>{props.children}</MarketDataContext.Provider>;
};

const setupMarketSubscriptionWS = async (credentials: User) => {
    await marketDataServiceClient.subscribeToPrices(
        {},
        { metadata: Metadata({ 'authorization-token': credentials.authToken }) }
    );
};

// let geee: any = null;
// websocket.addEventListener('message', (C) => {
//     const parsedMessage = JSON.parse(C == null ? void 0 : C.data);
//     if (parsedMessage != null && parsedMessage.marketDataFormattedPriceEvent) {
//         callback(parsedMessage);
//     }
//     if (parsedMessage.lastServerPingIntervalInMillis) {
//         const w = Date.now();
//         geee || (geee = w);
//         const k = JSON.stringify({
//             pingMessage: parsedMessage,
//             lastClientPingIntervalInMillis: String(w - geee)
//         });
//         (geee = w), websocket.send(k);
//     }
// });
