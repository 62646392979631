import { AccountPropertyDownstreamEvent } from '@/compiled_proto/com/celertech/staticdata/api/account/property/DownstreamAccountPropertyProto';
import { getAccountProperties } from '@/services/AccountService';
import { createAction, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';
import {
    LoginResponse,
    LoginResponseType
} from '../../compiled_proto/com/celertech/baseserver/communication/login/DownstreamLoginProto';
import { getUserAccounts, login, logout } from '../../services/UserService';
import { RootState } from '../store';

export const controlInitSubscriptions = createAction('control/initSubscriptions');
export const controlClearSubscriptions = createAction('control/clearSubscriptions');

export const doLogin = createAsyncThunk(
    'auth/login',
    async (
        { username, password, twoFactorToken }: { username: string; password: string; twoFactorToken?: string },
        { dispatch, rejectWithValue }
    ) => {
        try {
            const user = await login(username, password, twoFactorToken);

            if (user.loginResponseType === LoginResponseType.QR_TOKEN_CODE_REQUIRED) {
                dispatch(setUserRequires2FA(user));
                return user;
            } else {
                const authenticatedUser = {
                    clientRequestId: user.clientRequestId,
                    username,
                    authToken: user.sessionToken
                };
                const credentials = { ...authenticatedUser, accounts: [] };
                const userAccounts = await getUserAccounts(credentials);
                const accounts = await Promise.all(
                    userAccounts.map(async (account) => {
                        return {
                            code: account.code,
                            name: account.name,
                            properties: await getAccountProperties(account.code, credentials)
                        };
                    })
                );

                // We cannot wait to set the user in the "fulfilled reducer", since the user is used in middleware triggered by controlInitSubscriptions action
                dispatch(setUser({ ...authenticatedUser, accounts }));
                dispatch(setCurrentAccount(userAccounts[0].code));
                dispatch(setCurrentAccountName(userAccounts[0].name));
                dispatch(setCurrentEntityName(''));
                return userAccounts;
            }
        } catch (err) {
            return rejectWithValue(err as LoginError);
        }
    }
);

export const doLogout = createAsyncThunk(
    'auth/logout',
    async ({ credentials }: { credentials: User }, { dispatch, rejectWithValue }) => {
        try {
            // We cannot wait to set the user in the "pending reducer", since loginStatus is used in middleware triggered by controlClearSubscriptions action
            // namely "ServerEventsMiddleware"
            dispatch(setLoginStatus('loggingOut'));
            dispatch(controlClearSubscriptions());
            return await logout(credentials);
        } catch (err) {
            return rejectWithValue((err as LoginError).code);
        }
    }
);

export enum LoginStatus {
    SUCCESS = 1,
    BAD_CREDENTIALS = 2,
    UNEXPECTED = -1
}

export class LoginError extends Error {
    public constructor(public code: LoginStatus, message?: string) {
        super(message);
    }
}

export interface Account {
    code: string;
    name: string;
    properties: AccountPropertyDownstreamEvent[];
}

export interface User {
    clientRequestId: string;
    username: string;
    authToken: string;
    profileId?: string;
    accounts: Account[];
}

export interface AuthState {
    currentAccount?: string;
    currentAccountName?: string;
    currentEntityName?: string;
    loginStatus: 'loggedOut' | 'loggingIn' | 'loggedIn' | 'loggingOut';
    userRequires2FA?: LoginResponse;
    user?: User;
}

const initialState: AuthState = {
    loginStatus: 'loggedOut'
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setLoginStatus: (state, action: PayloadAction<'loggedOut' | 'loggingIn' | 'loggedIn' | 'loggingOut'>) => {
            state.loginStatus = action.payload;
        },
        setCurrentAccount: (state, action: PayloadAction<string>) => {
            state.currentAccount = action.payload;
        },
        setCurrentAccountName: (state, action: PayloadAction<string>) => {
            state.currentAccountName = action.payload;
        },
        setCurrentEntityName: (state, action: PayloadAction<string>) => {
            state.currentEntityName = action.payload;
        },
        setUser: (state, action: PayloadAction<User>) => {
            state.user = action.payload;
        },
        setUserRequires2FA: (state, action: PayloadAction<LoginResponse>) => {
            state.userRequires2FA = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(doLogin.pending, (state) => {
                state.loginStatus = 'loggingIn';
            })
            .addCase(doLogin.fulfilled, (state, action) => {
                state.loginStatus = 'loggedIn';
            })
            .addCase(doLogin.rejected, (state) => {
                state.loginStatus = 'loggedOut';
            })
            .addCase(doLogout.pending, (state) => {
                state.loginStatus = 'loggingOut';
            })
            .addCase(doLogout.fulfilled, (state, action) => {
                state.loginStatus = 'loggedOut';
                state.user = undefined;
                console.log('Logged out: ', action.payload.message);
            })
            .addCase(doLogout.rejected, (state, action) => {
                state.loginStatus = 'loggedOut';
                state.user = undefined;
                console.log('Logged out');
            });

        builder.addCase(PURGE, () => initialState);
    }
});

export const {
    setLoginStatus,
    setCurrentAccount,
    setCurrentAccountName,
    setCurrentEntityName,
    setUser,
    setUserRequires2FA
} = authSlice.actions;
export const selectCredentials = (state: RootState) => state.auth.user;
export const selectCurrentAccount = (state: RootState) => state.auth.currentAccount;
export const selectCurrentAccountName = (state: RootState) => state.auth.currentAccountName;
export const selectCurrentEntityName = (state: RootState) => state.auth.currentEntityName;

export default authSlice.reducer;
