import { Nullable } from '@/model/common';
import { InferType, number, object, string } from 'yup';

export const internalTransferRequestSchema = (balance: number, foundEntity?: any) =>
    object({
        transferType: object({ label: string(), value: string() }).required('Please select a internal transfer type.'),
        counterPartId: object({ label: string(), value: number() }).test(
            'valueNotUndefined',
            `Please select an counterpart.`,
            (counterpart) => !!counterpart.value
        ),
        currencyId: object({ label: string(), value: number() }).required('Currency cannot be empty!'),
        // amount value must be greater than 0
        amount: number()
            .test('amount', 'Transfer amount must be more than 0', (value) => {
                return (value || 0) > 0;
            })
            .test('amount', 'Transfer amount exceeded current balance', (value) => {
                if (foundEntity?.permissions?.includes('ACCOUNT_VIEW')) {
                    return (value || 0) <= balance;
                } else {
                    return true;
                }
            })
            .required('Amount cannot be empty!')
            .typeError('Invalid Input: Please input a number.'),
        requestDescription: string()
    });

export type InternalTransferRequestFormValues = InferType<ReturnType<typeof internalTransferRequestSchema>>;
export type InternalTransferRequestFormInput = Nullable<InternalTransferRequestFormValues>;
