import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FetchingStatus } from '../../model/fetchingStatus';
import { RootState } from '../store';

export interface Candle {
    symbol: string;
    opentime: number;
    open: number;
    high: number;
    low: number;
    close: number;
}

export interface PriceTick {
    symbol: string;
    time: number;
    open: number;
    high: number;
    low: number;
    close: number;
}

export interface ChartDataState {
    status: FetchingStatus;
    historicDataSupport: boolean;
    furtherStatus: FetchingStatus;
    bars: any[];
    updateBar: any;
    currentCandle?: Candle;
    priceTrend?: number;
    priceTicks: Record<string, PriceTick>;
}

const initialState: ChartDataState = {
    status: 'idle',
    furtherStatus: 'idle',
    bars: [],
    historicDataSupport: true,
    updateBar: null,
    priceTicks: {}
};

export const chartDataSlice = createSlice({
    name: 'chartData',
    initialState,
    reducers: {
        setUpdateBar: (state, action: PayloadAction<any>) => {
            state.updateBar = action.payload;
        },
        setHistoricSupport: (state, action: PayloadAction<boolean>) => {
            state.historicDataSupport = action.payload;
        },
        resetChart: (state) => {
            state.currentCandle = undefined;
            state.updateBar = undefined;
            state.priceTrend = undefined;
        },
        pushPriceChange: (state, action: PayloadAction<{ symbol: string; price: number; timestamp: number }>) => {
            // symbol is netdania syntax
            const { symbol, price, timestamp } = action.payload;

            const currentCandle = state.currentCandle;

            // Check that symbol matches latest candle - else ignore incoming price change.
            // Additional check - price updates for non-active symbol should be filtered away in middleware
            if (currentCandle && currentCandle.symbol === symbol) {
                const updateCandle = {
                    symbol,
                    opentime: timestamp,
                    open: price,
                    high: price,
                    low: price,
                    close: price
                };
                state.currentCandle = updateCandle;

                const tradingViewBar = {
                    symbol,
                    time: updateCandle.opentime,
                    close: updateCandle.close,
                    high: updateCandle.high,
                    low: updateCandle.low,
                    open: updateCandle.open
                };

                state.updateBar = tradingViewBar;
            } else if (currentCandle === undefined) {
                const updateCandle = {
                    symbol,
                    opentime: timestamp,
                    open: price,
                    high: price,
                    low: price,
                    close: price
                };
                state.currentCandle = updateCandle;

                const tradingViewBar = {
                    symbol,
                    time: updateCandle.opentime,
                    close: updateCandle.close,
                    high: updateCandle.high,
                    low: updateCandle.low,
                    open: updateCandle.open
                };

                state.updateBar = tradingViewBar;
            }
        },
        onPriceTick: (state, action: PayloadAction<{ symbol: string; price: number; timestamp: number }>) => {
            // symbol is netdania syntax
            const { symbol, price, timestamp } = action.payload;

            const tradingViewBar = {
                symbol,
                time: timestamp,
                close: price,
                high: price,
                low: price,
                open: price
            };

            state.priceTicks[symbol] = tradingViewBar;
        }
    }
});
export const { setUpdateBar, setHistoricSupport, pushPriceChange, onPriceTick, resetChart } = chartDataSlice.actions;

export const selectStatus = (state: RootState) => state.chartData.status;
export const selectFurtherStatus = (state: RootState) => state.chartData.furtherStatus;
export const selectBars = (state: RootState) => state.chartData.bars;
export const selectUpdateBar = (state: RootState) => state.chartData.updateBar;
export const selectCurrentCandle = (state: RootState) => state.chartData.currentCandle;
export const selectEarlistCandle = (state: RootState) => state.chartData.bars[0];
export const selectPriceTrend = (state: RootState) => state.chartData.priceTrend;
export const selectHistoricDataSupport = (state: RootState) => state.chartData.historicDataSupport;

export default chartDataSlice.reducer;
