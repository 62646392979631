import { portalAPI } from '@/helpers/environmentHelper';
import { useAppSelector } from '@/state/hooks';
import { selectCredentials, selectCurrentAccount } from '@/state/reducers/authSlice';
import axios from 'axios';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';

export interface SsoContext {
    jwt?: string;
    entities?: any;
    currenciesApi: any;
    balancesApi: any;
    bankInfoApi: any;
    walletInfoApi: any;
    linkedEntitiesApi: any;
    foundEntity?: any;
    permissions: string[];
    isSSOEnabled: boolean;
    canRequestDeposit: boolean;
    canRequestWithdrawal: boolean;
    depositEnabled: boolean;
    withdrawalEnabled: boolean;
}

export interface SSOProviderProps {
    children: React.ReactNode;
}

const depositEnabled = window.config.modules.deposit.enabled;
const withdrawalEnabled = window.config.modules.withdrawal.enabled;

const fetchOnce = {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false
};

const SSOContext = createContext({} as SsoContext);
export const useSso = () => useContext(SSOContext);
export const SSOProvider = (props: SSOProviderProps) => {
    const credentials = useAppSelector(selectCredentials);
    const currentAccount = useAppSelector(selectCurrentAccount);

    const [jwt, setJWT] = useState();

    useEffect(() => {
        async function initiateSSOWithdrawal() {
            const response = await axios.post(portalAPI('/api/auth/authenticate-via-sso'), {
                username: credentials?.username,
                celer_auth_token: credentials?.authToken
            });
            setJWT(response.data.jwt);
        }

        if (credentials && !jwt) initiateSSOWithdrawal();
        else if (!credentials) setJWT(undefined);
    }, [credentials]);

    const entitiesApi = useSWR<any>(portalAPI('/api/entity/list'), () => fetchEntities(jwt), fetchOnce);

    const currenciesApi = useSWR<any>(portalAPI('/api/cashentry/currencies'), () => fetchCurrencies(jwt), fetchOnce);

    const balancesApi = useSWR<any>(
        portalAPI('/api/account/balances'),
        () => fetchBalances(jwt, foundEntity?.entityId, !!foundEntity?.permissions?.includes('ACCOUNT_VIEW')),
        fetchOnce
    );

    const bankInfoApi = useSWR<any>(
        portalAPI('/api/bank-info'),
        () => fetchBankInfo(jwt, foundEntity?.entityId),
        fetchOnce
    );

    const walletInfoApi = useSWR<any>(
        portalAPI('/api/wallet'),
        () => fetchWalletInfo(jwt, foundEntity?.entityId),
        fetchOnce
    );

    const linkedEntitiesApi = useSWR<any>(
        portalAPI('/api/entity/linked-entities'),
        () => fetchLinkedEntities(jwt, foundEntity?.entityId),
        fetchOnce
    );

    const { data: entities, isLoading: isEntitiesLoading } = entitiesApi;

    const foundEntity = useMemo(() => {
        return entities?.data?.find((entity) =>
            entity.nameMappings.find((name) => name.source === 'CELER' && name.name === currentAccount)
        );
    }, [entities, currentAccount]);

    const isSSOEnabled = useMemo(() => !!jwt && !!foundEntity, [jwt, foundEntity]);

    const value = useMemo<SsoContext>(
        () => ({
            jwt,
            entities: entities?.data,
            currenciesApi,
            balancesApi,
            bankInfoApi,
            walletInfoApi,
            linkedEntitiesApi,
            isEntitiesLoading,
            canRequestDeposit: foundEntity?.permissions.includes('REQUEST_DEPOSIT'),
            canRequestWithdrawal: foundEntity?.permissions.includes('REQUEST_WITHDRAWAL'),
            permissions: foundEntity?.permissions || [],
            depositEnabled,
            withdrawalEnabled,
            isSSOEnabled,
            foundEntity
        }),
        [
            jwt,
            entities,
            currenciesApi,
            balancesApi,
            bankInfoApi,
            walletInfoApi,
            linkedEntitiesApi,
            isEntitiesLoading,
            depositEnabled,
            withdrawalEnabled,
            isSSOEnabled,
            foundEntity
        ]
    );

    useEffect(() => {
        if (jwt) {
            entitiesApi.mutate();
        }
    }, [jwt]);

    useEffect(() => {
        if (foundEntity) {
            currenciesApi.mutate();
            balancesApi.mutate();
            bankInfoApi.mutate();
            walletInfoApi.mutate();
            linkedEntitiesApi.mutate();
        }
    }, [foundEntity]);

    return <SSOContext.Provider value={value}>{props.children}</SSOContext.Provider>;
};

async function fetchEntities(jwt) {
    if (jwt) {
        return await axios.get(portalAPI('/api/entity/list'), {
            headers: { Authorization: `Bearer ${jwt}` }
        });
    }
    return { data: [] };
}
async function fetchCurrencies(jwt) {
    if (jwt) {
        return await axios.get(portalAPI('/api/cashentry/currencies'), {
            headers: { Authorization: `Bearer ${jwt}` }
        });
    }
    return { data: [] };
}
async function fetchBankInfo(jwt, entityId) {
    if (jwt && entityId) {
        return await axios.post(
            portalAPI('/api/bank-info'),
            { entityId },
            { headers: { Authorization: `Bearer ${jwt}` } }
        );
    }
    return { data: [] };
}
async function fetchBalances(jwt, entityId, hasAccountViewPermission) {
    if (jwt && entityId && hasAccountViewPermission) {
        return await axios.post(
            portalAPI('/api/account/balances'),
            { entityId },
            { headers: { Authorization: `Bearer ${jwt}` } }
        );
    }
    return { data: [] };
}
async function fetchWalletInfo(jwt, entityId) {
    if (jwt && entityId) {
        return await axios.post(
            portalAPI('/api/wallet'),
            { entityId },
            { headers: { Authorization: `Bearer ${jwt}` } }
        );
    }
    return { data: [] };
}
async function fetchLinkedEntities(jwt, entityId) {
    if (jwt && entityId) {
        return await axios.post(
            portalAPI('/api/entity/linked-entities'),
            { entityId },
            { headers: { Authorization: `Bearer ${jwt}` } }
        );
    }
    return { data: [] };
}
