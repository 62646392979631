import { simpleChartConfig } from '@/config/chart';
import { useAppDispatch } from '@/state/hooks';
import { fetchUserMarkets } from '@/state/reducers/marketPairSlice';
import { IChartingLibraryWidget } from '@/types/charting_library';
import useCSSVariables from '@/utils/hooks/useCSSVariables';
import { useEffect, useRef } from 'react';
import { useLocale } from 'react-aria';
import { getLocalChartState, initChartSettings } from './helpers/chartHelper';
import { useSimpleDatafeed } from './utils/hooks/useSimpleDatafeed';

const queryString = location.search;
const params = new URLSearchParams(queryString);
const symbol = params.get('symbol') || 'BTC/USD';

export default function Chart() {
    const dispatch = useAppDispatch();

    const { locale } = useLocale();
    const datafeed = useSimpleDatafeed(symbol);
    const variables = useCSSVariables();

    const widgetRef = useRef<IChartingLibraryWidget | null>(null);

    async function initChart() {
        window.chartLoaded = false;
        const chartState = getLocalChartState();
        if (chartState) chartState.charts[0].panes[0].sources[0].state.symbol = symbol;
        else window.chartLoaded = true;

        const TVWidget = new window.TradingView.widget(simpleChartConfig(symbol, locale, datafeed, variables));
        window.TVWidget = TVWidget;
        TVWidget.onChartReady(() => {
            widgetRef.current = TVWidget;
            if (chartState) {
                TVWidget.load(chartState);
                window.chartLoaded = true;
            }
            initChartSettings(TVWidget, variables);
        });
    }

    useEffect(() => {
        if (!params.get('symbol')) {
            history.replaceState({}, '', 'chart?symbol=BTC/USD');
        }
        dispatch(fetchUserMarkets()).then(initChart);
    }, []);

    return <div id="tv_chart_container" className="h-full" />;
}
